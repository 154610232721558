import React from "react";
import { observer } from "mobx-react";
import { Button, Upload } from "antd";
import { InputWrapper, SplitInputWrapperProps } from "../../functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons"
import { UploadInputBoxProps } from "./interface";

const MultipleUploadInputBox: React.FC<UploadInputBoxProps> = observer((props) => {
	const {
		inputProps,
	} = SplitInputWrapperProps(props);

	const onPreview = async (file:any) => {
		let src = file.url;
		if (file.fullimage) {
			src = file.fullimage;
		}
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		if (src.includes(".pdf") || src.includes(".PDF")) {
			window.open(src, '_blank');
		} else {
			if (file.file.type === "application/pdf") {
				window.open("_blank")?.document.write(
					'<title>' + file.file.name + '</title>' +
					'<body style="overflow: hidden; margin: 0">' +
					'<object width="100%" width="-webkit-fill-available" height="100%" height="-webkit-fill-available" type="application/pdf" data="' + src + '"></object>' +
					'</body>'
				);
			} else {
				const image = new Image();
				image.src = src;
				const imgWindow:any = window.open(src);
				imgWindow.document.write(image.outerHTML);
			}
		}
	};

	const eventProps = {
		onChange: (info: any) => {
			const { response } = info.file;
			
			if (info.file.status !== 'uploading') {
				//
			}
			if (info.file.status === 'done') {
				//
			} else if (info.file.status === 'error') {
				console.log("object e  : ", response);
			}
		},
	};

	const newFileList:any = props.fileList?.map((item:any) => {
		return item.key
	})

	return (
		<InputWrapper
			label={props.name}
			required={inputProps.is_mandatory}
			labelCol={{ span: 24 }}
			className="kycFileUpload"
		>
			<Upload
				name="doc_file"
				accept={!props.type ? ((props.allowType && props.allowType === "pdf") ? ".pdf" : ".png,.jpeg,.jpg") : ".pdf,.png,.jpeg,.jpg"}
				onPreview={onPreview}
				fileList={props.fileList && props.fileList}
				showUploadList={inputProps?.hideRemoveIcon ? { showRemoveIcon: false } : true}
				listType="picture"
				multiple={false}
				onRemove={(e) => { inputProps.onRemoveImage(e, inputProps.identifier) }}
				customRequest={(e) => { inputProps.customUpload(e, inputProps.id, inputProps.identifier) }}
				{...eventProps}
				className="upload-list-inline"
			>
				<Button className="upload_file" disabled={(props.fileList && newFileList.length >= props.max_uploads) ? true : false} icon={<FontAwesomeIcon icon={faFolder} />}>{`Upload ${(props.max_uploads <= 1) ? `` : `(Max: ${props.max_uploads})`}`}</Button>

			</Upload>
		</InputWrapper>
	);
})

export default MultipleUploadInputBox;