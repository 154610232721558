import { MobileValidation, replaceString } from "../../../config/Global";
import { RequestProps } from "../../../config/InterfacesAndTypes";
import { Messages } from "../../../config/Message/Common";
import { Regex } from "../../../config/Validators";

export const FranchiseeInquiryValidation: RequestProps = {
    fr_company_name: [
        { required: true, message: Messages.REQUIRED, whitespace: true, },
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 100,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 100),
        },
    ],
    pan: [
        { required: true, message: Messages.REQUIRED },
        {
            pattern: Regex.PAN,
            message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "PAN"),
        },
        {
            min: 10,
            message: Messages.PAN_INVALID_CHARACTERS,
        },
        {
            max: 10,
            message: Messages.PAN_INVALID_CHARACTERS,
        },
    ],
    aadhar_card: [
        { required: true, message: Messages.REQUIRED },
        {
            pattern: Regex.AADHAR,
            message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "aadhar"),
        },
        {
            min: 12,
            message: Messages.AADHAR_INVALID_CHARACTERS,
        },
        {
            max: 12,
            message: Messages.AADHAR_INVALID_CHARACTERS,
        },
    ],
    owner: [
        { required: true, message: Messages.REQUIRED, whitespace: true, },
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },
    ],
    birthdate: [{ required: true, message: Messages.REQUIRED }],
    mobile: [
        { required: true, message: Messages.REQUIRED },
        {
            pattern: Regex.mobile,
            whitespace: true,
            message: Messages.MOBILE_INVALID_CHARACTERS,
        },
        {
            min: 10,
            message: Messages.MOBILE_LENGTH,
        },
        {
            max: 10,
            message: Messages.MOBILE_LENGTH,
        },
        () => ({
            validator(value, rule) {
                if (rule && rule.length >= 10) {
                    const validate = MobileValidation(rule);
                    if (!validate) {
                        return Promise.reject(Messages.MOBILE_INVALID);
                    }
                }
                return Promise.resolve();
            },
        }),
    ],
    mobile2: [
        {
            pattern: Regex.mobile,
            whitespace: true,
            message: Messages.MOBILE_INVALID_CHARACTERS,
        },
        {
            min: 10,
            message: Messages.MOBILE_LENGTH,
        },
        {
            max: 10,
            message: Messages.MOBILE_LENGTH,
        },
        () => ({
            validator(value, rule) {
                if (rule && rule.length >= 10) {
                    const validate = MobileValidation(rule);
                    if (!validate) {
                        return Promise.reject(Messages.MOBILE_INVALID);
                    }
                }
                return Promise.resolve();
            },
        }),
    ],
    phone1: [
        {
            pattern: Regex.PHONE,
            whitespace: true,
            message: Messages.MOBILE_INVALID_CHARACTERS,
        },
        {
            min: 10,
            message: Messages.MOBILE_LENGTH,
        },
        {
            max: 10,
            message: Messages.MOBILE_LENGTH,
        },
        () => ({
            validator(value, rule) {
                if (rule && rule.length >= 10) {
                    const validate = MobileValidation(rule);
                    if (!validate) {
                        return Promise.reject(Messages.MOBILE_INVALID);
                    }
                }
                return Promise.resolve();
            },
        }),
    ],
    phone2: [
        {
            pattern: Regex.PHONE,
            whitespace: true,
            message: Messages.LANDLINE_INVALID_CHARACTERS,
        },
        {
            min: 8,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
        },
        {
            max: 20,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20),
        },
    ],
    wp_number: [
        { required: true, message: Messages.REQUIRED },
        {
            pattern: Regex.mobile,
            whitespace: true,
            message: Messages.WHATSAPP_INVALID_CHARACTERS,
        },
        {
            min: 10,
            message: Messages.MOBILE_LENGTH,
        },
        {
            max: 10,
            message: Messages.MOBILE_LENGTH,
        },
        () => ({
            validator(value, rule) {
                if (rule && rule.length >= 10) {
                    const validate = MobileValidation(rule);
                    if (!validate) {
                        return Promise.reject(Messages.WHATSAPP_INVALID);
                    }
                }
                return Promise.resolve();
            },
        }),
    ],
    fax: [
        {
            pattern: Regex.PHONE,
            whitespace: true,
            message: Messages.FAX_INVALID_CHARACTERS,
        },
        {
            min: 8,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
        },
        {
            max: 20,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20),
        },
    ],
    email: [
        { required: true, message: Messages.REQUIRED },
        {
            pattern: Regex.EMAIL_ID,
            message: Messages.ERROR_EMAIL,
        },
        {
            min: 8,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
        },
        {
            max: 70,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 70),
        },
    ],
    skype_id: [
        {
            min: 6,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 6),
        },
        {
            max: 100,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 100),
        },
    ],
    ref_by: [
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },
    ],
    ref_mobile: [
        {
            pattern: Regex.mobile,
            whitespace: true,
            message: Messages.MOBILE_INVALID_CHARACTERS,
        },
        {
            min: 10,
            message: Messages.MOBILE_LENGTH,
        },
        {
            max: 10,
            message: Messages.MOBILE_LENGTH,
        },
        () => ({
            validator(value, rule) {
                if (rule && rule.length >= 10) {
                    const validate = MobileValidation(rule);
                    if (!validate) {
                        return Promise.reject(Messages.MOBILE_INVALID);
                    }
                }
                return Promise.resolve();
            },
        }),
    ],
    address1: [
        { required: true, whitespace: true, message: Messages.REQUIRED },
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 200,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 200),
        },
    ],
    address2: [
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },
    ],
    address3: [
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },
    ],
    state_id: [{ required: true, message: Messages.REQUIRED }],
    city_id: [{ required: true, message: Messages.REQUIRED }],
    city: [
        { required: true, message: Messages.REQUIRED, whitespace: true, },
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },
    ],
    pincode_id: [{ required: true, message: Messages.REQUIRED }],
    loc_name: [
        { required: true, message: Messages.REQUIRED, whitespace: true, },
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },
    ],
    loc_contact_person:
        [{ required: true, message: Messages.REQUIRED, whitespace: true, },
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },],
    loc_phone1: [
        {
            pattern: Regex.mobile,
            whitespace: true,
            message: Messages.LANDLINE_INVALID_CHARACTERS,
        },
        {
            min: 10,
            message: Messages.MOBILE_LENGTH,
        },
        {
            max: 10,
            message: Messages.MOBILE_LENGTH,
        },
        () => ({
            validator(value, rule) {
                if (rule && rule.length >= 10) {
                    const validate = MobileValidation(rule);
                    if (!validate) {
                        return Promise.reject(Messages.MOBILE_INVALID);
                    }
                }
                return Promise.resolve();
            },
        }),
    ],
    loc_phone2: [
        {
            pattern: Regex.mobile,
            whitespace: true,
            message: Messages.LANDLINE_INVALID_CHARACTERS,
        },
        {
            min: 10,
            message: Messages.MOBILE_LENGTH,
        },
        {
            max: 10,
            message: Messages.MOBILE_LENGTH,
        },
        () => ({
            validator(value, rule) {
                if (rule && rule.length >= 10) {
                    const validate = MobileValidation(rule);
                    if (!validate) {
                        return Promise.reject(Messages.MOBILE_INVALID);
                    }
                }
                return Promise.resolve();
            },
        }),
    ],
    loc_incharge_name:
        [{ required: true, message: Messages.REQUIRED, whitespace: true, },
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },],
    loc_incharge_email: [
        {
            pattern: Regex.EMAIL_ID,
            message: Messages.ERROR_EMAIL,
        },
        {
            min: 8,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
        },
        {
            max: 70,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 70),
        },],
    gst_no: [
        { required: true, whitespace: true, message: Messages.REQUIRED },
        {
            min: 15,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 15),
        },
        {
            pattern: Regex.GST_IN,
            whitespace: true,
            message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "GST"),
        },
        ({ getFieldValue }) => ({
            validator(value, rule) {
                if (rule && rule.length >= 15 && getFieldValue("pan") !== "") {
                    const panNo = getFieldValue("pan");
                    if (!(panNo === rule.substring(2, 12))) {
                        return Promise.reject(Messages.GST_PAN_DOES_NOT_MATCH);
                    }
                }
                return Promise.resolve();
            }
        })
    ],
    ct_id: [{ required: true, message: Messages.REQUIRED }],
    pt_id: [{ required: true, message: Messages.REQUIRED }],
    sez_id: [{ required: true, message: Messages.REQUIRED }],
    loc_bank_account: [
        { required: true, whitespace: true, message: Messages.REQUIRED },
        { pattern: Regex.ACCOUNT, message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "acc.no.") },
        {
            min: 8,
            message: replaceString(Messages.MIN_LENGTH_DIGIT_ERROR, "#MIN#", 8),
        },
        {
            max: 20,
            message: replaceString(Messages.MAX_LENGTH_DIGIT_ERROR, "#MAX#", 20),
        },
    ],
    loc_bank_ifsc: [
        { required: true, whitespace: true, message: Messages.REQUIRED },
        {
            min: 8,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
        },
        {
            max: 20,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20),
        },
    ],
    loc_bank_branch: [
        { required: true, message: Messages.REQUIRED, whitespace: true, },
        {
            min: 3,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
        },
        {
            max: 50,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
        },
    ],
    loc_bank_id: [{ required: true, message: Messages.REQUIRED }],
    loc_bank_micr: [
        { pattern: Regex.ACCOUNT, message: replaceString(Messages.INVALID_DIGIT_ATTR, "#ATTR#", "Micr") },
        {
            min: 8,
            message: replaceString(Messages.MIN_LENGTH_DIGIT_ERROR, "#MIN#", 8),
        },
        {
            max: 20,
            message: replaceString(Messages.MAX_LENGTH_DIGIT_ERROR, "#MAX#", 20),
        },
    ],
    loc_bank_swift: [
        { pattern: Regex.ALPHANUMERIC, message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "swift") },
        {
            min: 8,
            message: replaceString(Messages.MIN_LENGTH_DIGIT_ERROR, "#MIN#", 8),
        },
        {
            max: 20,
            message: replaceString(Messages.MAX_LENGTH_DIGIT_ERROR, "#MAX#", 20),
        },
    ],
    pincode: [
        { required: true, whitespace: true, message: Messages.REQUIRED },
        {
            pattern: Regex.PINCODE,
            message: Messages.PINCODE_INVALID_CHARACTERS,
        },
        {
            min: 6,
            message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 6),
        },
        {
            max: 6,
            message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 6),
        },
    ],
};
