import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Col, Row, Image as PreviewImage, Modal, Button } from "antd";
import { InputBox } from "../../../../components/AntdAddons";
import { vsmNotify } from "../../../../config/Notify";
import { replaceString } from "../../../../config/Global";
import { Messages } from "../../../../config/Message/Common";
import { Constant } from "../../../../config/Constant";
import imageCompression from "browser-image-compression";
import useStore from "../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";

const LocKycFormComponent: React.FC<any> = observer((props) => {
	const { locKycFormDetails, updateLocKycPayload, form, setLocDocIds, setDisabled } = props
	const { FRANCHISEE_INQUIRY: { franchiseeDetailsData } } = useStore()
	const [locKycFileList, setLocKycFileList] = useState<any>([]);
	const [deleteModal, setDeleteModal] = useState(false);
	const [removeFile, setRemoveFileFile] = useState<any>(null);

	const customUpload = ({ file }: any, type_id: any, key: any) => {
		const option = {
			useWebWorker: true,
			initialQuality: Constant.IMAGEQUALITY,
			maxWidthOrHeight: Constant.MAXWIDTHORHEIGHT,
		};
		if (file.type !== "application/pdf" && file.size / 1048576 > 25) {
			vsmNotify.error({
				message: replaceString(Messages.IMAGE_SIZE_VALIDATION, "#SIZE#", "25 MB"),
			});
		} else if (file.type === "application/pdf" && file.size / 1048576 > 1) {
			vsmNotify.error({
				message: replaceString(Messages.IMAGE_SIZE_VALIDATION, "#SIZE#", "1 MB"),
			});
		} else if (file.type !== "application/pdf") {
			imageCompression(file, option).then((result) => {
				if ((result.size / 1045504) <= Constant.COMPRESS_SIZE) {
					addFileToList(type_id, key, result)
				} else {
					vsmNotify.error({
						message: Messages.COMPRESS_ERROR
					});
				}
				return result;
			}).catch((error) => {
				vsmNotify.error({
					message: error.message
				});
			});
		}
		else {
			file.thumbUrl = Constant.PDFICON;
			addFileToList(type_id, key, file);
		}
	};

	useEffect(() => {
		if (!locKycFormDetails || locKycFormDetails.length === 0) return;
		const allFileList: any[] = [];
		locKycFormDetails.forEach((item: any) => {
			if (item.loc_inq_docs && item.loc_inq_docs.length > 0) {
				item.loc_inq_docs.forEach((innerItem: any) => {
					if (innerItem.doc_file) {
						const fileObject = {
							uid: `-${innerItem.id}`,
							name: innerItem.doc_file.substring(innerItem.doc_file.lastIndexOf("/") + 1),
							status: "done",
							url: Constant.S3BUCKET_API_BASEURL + innerItem.thumb_file,
							fullimage: Constant.S3BUCKET_API_BASEURL + innerItem.doc_file,
							doc_id: innerItem.id,
							key: item.key,
							type_id: item.id
						};
						allFileList.push(fileObject);
					}
				});
			}
		});

		setLocKycFileList(allFileList);
	}, [locKycFormDetails, form]);

	const addFileToList = (type_id: any, key: string, file: any) => {
		setLocKycFileList((prevFileList: any[]) => {
			const updatedList = [...prevFileList];
			const existingFile = updatedList.find(item => item.type_id === type_id && item.key === key);

			if (!existingFile || existingFile.name !== file.name) {
				updatedList.push({
					type_id: type_id,
					key: key,
					uid: file.uid,
					name: file.name,
					status: "done",
					file: file,
					is_deleted: 0,
					originFileObj: file,
					thumbUrl: file.type === "application/pdf" ? Constant.PDFICON : file.thumbUrl
				});
				updateLocKycPayload(updatedList)
			}
			return updatedList;
		});
	};

	const openDeleteModal = (file: any) => {
		const fieldName = locKycFormDetails.find((field: any) => 
			file.type_id === field.id && file.key === field.key
		)?.name
		setRemoveFileFile({ file , fieldName }); 
		setDeleteModal(true);
	};
	
	const closeDeleteModal = () => {
		setDeleteModal(false);
		setRemoveFileFile(null);
	};
	
	const deleteLocKycDocument = () => {
		if (removeFile?.file) {
			onRemoveImage(removeFile.file);
			closeDeleteModal();
		}
	};

	const onRemoveImage = (file: any) => {
		setLocKycFileList((prevFileList: any[]) => {
			const updatedList = prevFileList.filter(item => item.uid !== file.uid);
			if (file.doc_id) {
				setLocDocIds((prevIds: any) => [...prevIds, file.doc_id]);
				updateLocKycPayload([...updatedList, { doc_id: file.doc_id, is_deleted: 1 }]);
			} else {
				updateLocKycPayload(updatedList);
			}
			setDisabled(false)
			return updatedList;
		});
	};

	return (
		<>
			{
				locKycFormDetails && locKycFormDetails.length > 0 && locKycFormDetails.map((field: any) => {
					const locKycFieldFilesList = locKycFileList.filter((file: any) => file.type_id === field.id && file.key === field.key);
					return <>
						<Row gutter={10} className="">
							<Col span={24}>
								{franchiseeDetailsData && franchiseeDetailsData.length !== 0 && franchiseeDetailsData?.status !== Constant.FRANCHISEE_INQUIRY_STATUS_ID.SAVE_AS_A_DRAFT &&
									<div className="kycFileUpload kycFileUploadView">
										<label>{field.name}</label>
										{locKycFieldFilesList.length > 0 ? (
											locKycFieldFilesList.map((item: any, i: any) => (
												item.fullimage.includes(".pdf") || item.fullimage.includes(".PDF") ? (
													<a key={`${field.key}-${i}`} href={item.fullimage} target="_blank" rel="noreferrer">
														<img height={75} alt={item.url} src={item.url} />
													</a>
												) : (
													<PreviewImage key={`${field.key}-${i}`} width={100} src={item.fullimage} />
												)
											))
										) : (
											<p>File not uploaded</p>
										)}
									</div>}

								{(!franchiseeDetailsData || franchiseeDetailsData?.length === 0 || franchiseeDetailsData?.status === Constant.FRANCHISEE_INQUIRY_STATUS_ID.SAVE_AS_A_DRAFT) &&
									<InputBox.MultipleUpload
										name={field.name}
										identifier={field.key}
										id={field.id}
										is_mandatory={field.is_mandatory}
										max_uploads={field.max_uploads}
										fileList={locKycFieldFilesList}
										allowType=".pdf,.png,.jpeg,.jpg"
										type='pdf'
										customUpload={(options: any) => customUpload(options, field.id, field.key)}
										onRemoveImage={(file: any) => openDeleteModal(file)}
									/>
								}
							</Col>
						</Row>
					</>
				})
			}
			<Row gutter={30} className="mt-20">
				<Col xs={{ span: 24 }}>
					<span className="proxy-notes"><strong>* Notes:</strong></span>
					<ul className="help_text">
						<li>Image size should not exceed 25 MB</li>
						<li>PDF size should not exceed 1 MB</li>
						<li>Valid formats are PDF, JPEG, JPG and PNG</li>
						<li>Compress image size should not exceed 1 MB</li>
					</ul>
				</Col>
			</Row>
			<Modal
				centered
				className="deleteModal"
				title="Delete KYC document?"
				visible={deleteModal}
				closeIcon={<FontAwesomeIcon icon={faTimes} />}
				onCancel={closeDeleteModal}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				footer={[
					<Button
						key="2"
						htmlType="button"
						className="cancelBtn mr-25"
						onClick={closeDeleteModal}
					>
						Cancel
					</Button>,
					<Button
						key="1"
						htmlType="button"
						onClick={deleteLocKycDocument}
						type="primary"
						danger
					>
						Delete
					</Button>,
				]}
			>
				<Row align="middle">
					<Col span={24} className="text-center">
						<h3>
							<span className="danger_color"><FontAwesomeIcon className="" icon={faExclamationTriangle} /></span> Would you like to delete <span>{removeFile?.fieldName}</span>?
						</h3>
					</Col>
				</Row>
			</Modal>
		</>
	)
})

export default LocKycFormComponent