import React, { useEffect } from "react";
import { Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TrackBtn from "../../../components/TrackingPage/TrackBtn";
import OfferBtn from "../../../components/MessageIcon/OfferBtn";

const MainLayout: React.FC = observer(() => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token")

	useEffect(() => {
		if (!token) {
			navigate("/");
		}
	}, [token, navigate]);

	return (
		<Layout className={`main__page__wrapper has__header`}>
			<Header />
			<TrackBtn />
			<OfferBtn />
			<Layout.Content className="main__page__content">
				<Outlet />
			</Layout.Content>
			<Footer />
		</Layout>
	);
});

export default MainLayout;
