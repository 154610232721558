export const Constant = {
    S3BUCKET_API_BASEURL: (process.env.React_APP_S3bucket) ? process.env.React_APP_S3bucket : "https://cmsmanagemant.s3.ap-south-1.amazonaws.com/",
    FORMDATE: 31,
    SHIPMENT_3RD_DIGIT:3,
    SHIPMENT_7TH_DIGIT:8,
    CLIENT_TYPE: {
        NORMAL: 1,
        CORPORATE: 2,
        BARTER: 3,
    },
    BOOKING_STATUS: {
        IN_TRANSIT: "In Transit"
    },
    ROLES: {
        CLIENT: 14,
        FRANCHISEE_INQUIRY: 22
    },
    UTCOFFSET : 330,
    DEFAULT_DATE_FORMAT: "DD/MM/YYYY",
    IMAGEQUALITY: 0.3,
    MAXWIDTHORHEIGHT: 1024,
    COMPRESS_SIZE: 3,
    FRANCHISEE_INQUIRY_STATUS_ID: {
        SAVE_AS_A_DRAFT : 10,
        FINALIZE : 20,
        READY_FOR_FRANCHISEE_CREATION: 30,
        GENERATE_FRANCHISEE: 40,
        FRANCHISEE_ON_BOARD: 50,
    },
    PDFICON: `${(process.env.React_APP_S3bucket) ? process.env.React_APP_S3bucket : "https://cmsmanagemant.s3.ap-south-1.amazonaws.com/"}branding/pdf_file_icon.svg`,
};

export const IMAGE_TYPE = {
    jpeg: "image/jpeg",
    jpg: "image/jpg",
    png: "image/png",
    pdf: "application/pdf",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    doc: "application/msword"
  };


