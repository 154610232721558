import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, Col, Divider, Form, Row, Spin, Switch } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import useStore from "../../../../store";
import { Constant } from "../../../../config/Constant";
import moment from "moment";
import { FranchiseeInquiryValidation } from "../Validation";
import { vsmNotify } from "../../../../config/Notify";
import { debounce } from "lodash";
import { SearchOutlined } from '@ant-design/icons';
import FrKycFormComponent from "./FrKycFormComponent";
import LocKycFormComponent from "./LocKycFormComponent";

const FormComponent: React.FC<any> = observer((props) => {
	const {
		form,
		loading,
		setLoading,
		fetchState,
		setFetchState,
		fetchPinCode,
		setFetchPinCode,
		fetchPerState,
		setFetchPerState,
		fetchPerPinCode,
		setFetchPerPinCode,
		fetchLocCity,
		setFetchLocCity,
		pinCodeList,
		setPinCodeList,
		permanentPinCode,
		setPermanentPinCode,
		fetchLocState,
		setFetchLocState,
		fetchLocPinCode,
		setFetchLocPinCode,
		stdCode,
		setStdCode,
		stateList,
		setStateList,
		permanentState,
		setPermanentState,
		fetchBloodGrpList,
		setFetchBloodGrpList,
		gstDisplay,
		setGstDisplay,
		fetchLocationTypes,
		setFetchLocationTypes,
		fetchPartnerShipType,
		setFetchPartnerShipType,
		fetchSez,
		setFetchSez,
		buttonEvent,
		setButtonEvent,
		fetchBank,
		setFetchBank,
		fetchLocBankState,
		setFetchBankLocState,
		fetchLocBankCity,
		setFetchLocBankCity,
		locStateList,
		setLocStateList,
		locBankStateList,
		setLocBankStateList,
		locBankCityList,
		setLocBankCityList,
	} = props

	const {
		FRANCHISEE_INQUIRY,
		FRANCHISEE_INQUIRY: {
			createFranchiseeInquiryData,
			dropdown_state_list,
			getStateList,
			dropdown_pin_code_list,
			getPinCodeList,
			dropdown_city_list,
			getCityList,
			dropdown_blood_grp_list,
			getBloodGrpList,
			dropdown_center_types_list,
			getCenterTypesList,
			dropdown_partnership_types_list,
			getPartnerShipTypesList,
			dropdown_sez_list,
			getSezList,
			getBankInfo,
			dropdown_bank_list,
			getBanksList,
			DetailsData,
			getKycForm,
			franchiseeDetailsData,
		},
	} = useStore()

	const [disabled, setDisabled] = useState(true);
	const [frKycFormDetails, setFrKycFormDetails] = useState([])
	const [frKycPayload, setFrKycPayload] = useState<any>([]);
	const [frDocIds, setFrDocIds] = useState<number[]>([]);
	const [locKycFormDetails, setLocKycFormDetails] = useState([])
	const [locKycPayload, setLocKycPayload] = useState<any>([]);
	const [locDocIds, setLocDocIds] = useState<number[]>([]);

	const isViewValues = (!franchiseeDetailsData || franchiseeDetailsData.length === 0 || franchiseeDetailsData?.status === Constant.FRANCHISEE_INQUIRY_STATUS_ID.SAVE_AS_A_DRAFT) ? false : true

	const handleChange = debounce(() => {
		form
			.validateFields()
			.then(() => {
				setDisabled(false);
			})
			.catch(() => {
				setDisabled(true);
			});
	}, 500);

	const handleStateChange = () => {
		form.setFieldsValue({
			pincode_id: null,
		});
		setPinCodeList()
		setFetchPinCode(true)
		FRANCHISEE_INQUIRY.dropdown_pin_code_list = null;
	};

	const handlePerStateChange = () => {
		form.setFieldsValue({
			p_pincode_id: null,
		});
		setFetchPerPinCode(true);
		setPermanentPinCode();
		FRANCHISEE_INQUIRY.dropdown_pin_code_list = null;
	};

	const handleLocStateChange = () => {
		form.setFieldsValue({
			loc_city_id: null,
		});
		form.setFieldsValue({
			loc_pincode_id: null,
		});
		setFetchLocCity(true);
		setFetchPinCode(true);
		FRANCHISEE_INQUIRY.dropdown_city_list = null;
		FRANCHISEE_INQUIRY.dropdown_pin_code_list = null;
	};

	const handleLocBankStateChange = () => {
		form.setFieldsValue({
			loc_bank_city_id: null,
		});
		setFetchLocBankCity(true)
		FRANCHISEE_INQUIRY.dropdown_city_list = null;
	}

	const handleLocCityChange = () => {
		form.setFieldsValue({
			loc_pincode_id: null,
		});
		setFetchLocPinCode(true);
		FRANCHISEE_INQUIRY.dropdown_pin_code_list = null;
	}

	const handleSameAsAboveChange = (e: any) => {
		handleChange()
		if (e.target.checked) {
			form.setFieldsValue({
				p_address1: form.getFieldValue("address1"),
				p_address2: form.getFieldValue("address2"),
				p_address3: form.getFieldValue("address3"),
				p_city_name: form.getFieldValue("city_name")
			});
			form.resetFields(['p_state_id', 'p_pincode_id'])
			getStateList().then((data: any) => {
				setPermanentState(data.states)
				setFetchPerState(false)
				handleChange()
				form.setFieldsValue({ p_state_id: form.getFieldValue("state_id") })
			})
			getPinCodeList({
				state_id: form.getFieldValue("state_id"),
			}).then((data: any) => {
				setPermanentPinCode(data.pincodes)
				setFetchPerPinCode(false)
				handleChange()
				form.setFieldsValue({ p_pincode_id: form.getFieldValue("pincode_id") })
			})
		} else {
			form.setFieldsValue({
				p_pincode_id: null,
				p_city_name: null,
				p_state_id: null,
				p_address1: null,
				p_address2: null,
				p_address3: null,
			})
		}
	}

	const updateFrKycPayload = (updatedFiles: any) => {
		setFrKycPayload(updatedFiles);
	};

	const updateLocKycPayload = (updatedFiles: any) => {
		setLocKycPayload(updatedFiles);
	};

	const onSubmit = async (data: any, type?: any) => {
		if (type?.is_draft === 1) {
			try {
				setLoading(true)
				await form.validateFields();
			} catch (error) {
				return;
			}
		}

		const formData = new FormData();
		Object.keys(data).forEach((key) => {
			if (!['birthdate', "loc_gst_no"].includes(key)) {
				formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
			}
		});
		if (frKycPayload.length > 0) {
			frKycPayload.forEach((fileObj: any, index: number) => {
				if (fileObj.file && fileObj.is_deleted === 0) {
					formData.append(`fr_type_id[${index}]`, fileObj.type_id);
					formData.append(`fr_kyc_files[${fileObj.key}][${index}]`, fileObj.file);
				}
			});
		}
		if (frDocIds.length > 0) {
			frDocIds.forEach((id: any, index) => {
				formData.append(`fr_doc_id[${index}]`, id);
			});
		}
		if (locKycPayload.length > 0) {
			locKycPayload.forEach((fileObj: any, index: number) => {
				if (fileObj.file && fileObj.is_deleted === 0) {
					formData.append(`loc_type_id[${index}]`, fileObj.type_id);
					formData.append(`loc_kyc_files[${fileObj.key}][${index}]`, fileObj.file);
				}
			});
		}
		if (locDocIds.length > 0) {
			locDocIds.forEach((id: any, index) => {
				formData.append(`loc_doc_id[${index}]`, id);
			});
		}
		if (data.birthdate) {
			formData.append("birthdate", moment(data.birthdate).utcOffset(Constant.UTCOFFSET).format("YYYY-MM-DD"));
		}
		if (data.loc_gst_no) {
			formData.append("loc_gst_no", data.loc_gst_no);
		}
		formData.append("is_draft", type?.is_draft === 0 ? "0" : "1");

		await createFranchiseeInquiryData(formData)
			.then((data?: any) => {
				vsmNotify.success({ message: data?.STATUS?.NOTIFICATION?.[0] });
				form.resetFields();
				fetchDetailsData();
				fetchKycDetails();
				setFrDocIds([]);
				setLocDocIds([]);
				setFrKycPayload([])
				setLocKycPayload([])
			}).catch((e: any) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			}).finally(() => {
				setLoading(false);
			})
	};

	const disableDate = (current: any) => {
		return (
			current &&
			(current > moment().utcOffset(Constant.UTCOFFSET).endOf("day") ||
				current > moment().utcOffset(Constant.UTCOFFSET).subtract(18, "years").startOf("day"))
		);
	};

	const fetchDetailsData = async () => {
		setLoading(true);
		await DetailsData().then((data: any) => {
			setStateList(data?.state && [data.state])
			setPermanentState(data?.p_state && [data.p_state])
			setLocStateList(data?.loc_state && [data.loc_state])
			setLocBankStateList(data?.loc_bank_state && [data.loc_bank_state])
			setPinCodeList(data?.pincode && [data.pincode])
			setPermanentPinCode(data?.p_pincode && [data.p_pincode])
			setLocBankCityList(data?.loc_bank_city && [data.loc_bank_city])
			setGstDisplay(data?.loc_is_gst_registered ?? 1)
			setStdCode(data?.loc_city?.std_code && data.loc_city.std_code)
			FRANCHISEE_INQUIRY.dropdown_blood_grp_list = data?.blood_group && [{ id: data.blood_group.id, name: data.blood_group.name }]
			FRANCHISEE_INQUIRY.dropdown_partnership_types_list = data?.partnership_types && [{ id: data.partnership_types.id, name: data.partnership_types.name }]
			FRANCHISEE_INQUIRY.dropdown_center_types_list = data?.center_types && [{ id: data.center_types.id, name: data.center_types.name }]
			FRANCHISEE_INQUIRY.dropdown_sez_list = data?.sez && [{ id: data.sez.id, name: data.sez.name }]
			FRANCHISEE_INQUIRY.dropdown_bank_list = data?.bank && [{ id: data.bank.id, name: data.bank.name }]
			FRANCHISEE_INQUIRY.dropdown_city_list = data?.loc_city && [{ id: data.loc_city.id, name: data.loc_city.name }]
			FRANCHISEE_INQUIRY.dropdown_pin_code_list = data?.loc_pincode && [{ id: data.loc_pincode.id, pincode: data.loc_pincode.pincode }]
			form.setFieldsValue({
				company: data.company,
				pan: data.pan,
				aadhar: data.aadhar,
				owner_name: data.owner_name,
				birthdate: data.birthdate && moment(data.birthdate),
				blood_grp_id: data.blood_grp_id,
				mobile: data.mobile,
				phone1: data.phone1,
				phone2: data.phone2,
				whatsapp_no: data.whatsapp_no,
				fax: data.fax,
				email: data.email,
				skype_id: data.skype_id,
				ref_by: data.ref_by,
				ref_mobile: data.ref_mobile,
				address1: data.address1,
				address2: data.address2,
				address3: data.address3,
				state_id: data.state_id,
				city_name: data.city_name,
				pincode_id: data.pincode_id,
				p_address1: data.p_address1,
				p_address2: data.p_address2,
				p_address3: data.p_address3,
				p_state_id: data.p_state_id,
				p_city_name: data.p_city_name,
				p_pincode_id: data.p_pincode_id,
				loc_name: data.loc_name,
				loc_is_gst_registered: data.loc_is_gst_registered ?? 1,
				loc_gst_no: data.loc_gst_no ? data.loc_gst_no : null,
				loc_contact_person: data.loc_contact_person,
				loc_address1: data.loc_address1,
				loc_address2: data.loc_address2,
				loc_address3: data.loc_address3,
				loc_state_id: data.loc_state_id,
				loc_city_id: data.loc_city_id,
				loc_pincode_id: data.loc_pincode_id,
				loc_mobile1: data.loc_mobile1,
				loc_mobile2: data.loc_mobile2,
				phoneStdCode: data?.loc_city?.std_code,
				loc_phone1: data.loc_phone1,
				loc_phone2: data.loc_phone2,
				loc_whatsapp_no: data.loc_whatsapp_no,
				loc_fax: data.loc_fax,
				loc_email: data.loc_email,
				loc_skype_id: data.loc_skype_id,
				loc_incharge_name: data.loc_incharge_name,
				loc_incharge_mobile: data.loc_incharge_mobile,
				loc_incharge_email: data.loc_incharge_email,
				ct_id: data.ct_id,
				pt_id: data.pt_id,
				sez_id: data.sez_id,
				loc_bank_accno: data.loc_bank_accno,
				loc_bank_ifsc: data.loc_bank_ifsc,
				loc_bank_branch: data.loc_bank_branch,
				loc_bank_id: data.loc_bank_id,
				loc_bank_micr: data.loc_bank_micr,
				loc_bank_swift: data.loc_bank_swift,
				loc_bank_address1: data.loc_bank_address1,
				loc_bank_address2: data.loc_bank_address2,
				loc_bank_address3: data.loc_bank_address3,
				loc_bank_state_id: data.loc_bank_state_id,
				loc_bank_city_id: data.loc_bank_city_id,
				loc_bank_pincode: data.loc_bank_pincode,
			})
		}).catch((error: any) => {
			vsmNotify.error({ message: error?.STATUS?.NOTIFICATION?.[0] });
		}
		).finally(() => {
			setLoading(false)
		})
	};

	const fetchKycDetails = async () => {
		await getKycForm().then((data: any) => {
			setFrKycFormDetails(data.fr_doc_types)
			setLocKycFormDetails(data.loc_doc_types)

		})
	}

	const onMountData = async () => {
		await fetchDetailsData()
		await fetchKycDetails()
		handleChange()

	}

	useEffect(() => {
		onMountData()
	}, [form])

	return (
		<>
			<div className="form_section">
				<div className="container">
					<div className="summary_card fra_inquiry_details_form">
						<FormBox
							form={form}
							onFinish={onSubmit}
							onChange={handleChange}>
							<div className="form">
								<div className="card_title">
									<h3>Franchisee Details</h3>
								</div>
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Franchisee Name"
											placeholder="Franchisee"
											name="company"
											className="inputText"
											onChange={() => {
												handleChange();
											}}
											disabled={isViewValues}
											rules={FranchiseeInquiryValidation.fr_company_name}
										/>
									</Col>
								</Row>
								<Divider />
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Owner’s PAN"
											placeholder="Owner’s PAN"
											maxLength={10}
											name="pan"
											className="inputText"
											onChange={() => {
												handleChange();
											}}
											disabled={isViewValues}
											rules={FranchiseeInquiryValidation.pan}
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											required
											type="text"
											label="Aadhar Card"
											placeholder="Aadhar Card"
											maxLength={12}
											name="aadhar"
											className="inputText"
											onChange={() => {
												handleChange();
											}}
											disabled={isViewValues}
											rules={FranchiseeInquiryValidation.aadhar_card}
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Owner Name"
											placeholder="Owner"
											name="owner_name"
											className="inputText"
											onChange={() => {
												handleChange();
											}}
											disabled={isViewValues}
											rules={FranchiseeInquiryValidation.owner}
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.DatePicker
											required
											label="Date of Birth"
											placeholder="Date of Birth"
											name="birthdate"
											className="inputText"
											onChange={() => {
												handleChange();
											}}
											format={Constant.DEFAULT_DATE_FORMAT}
											disabled={isViewValues}
											defaultPickerValue={(moment().utcOffset(Constant.UTCOFFSET).subtract(18, "years").startOf("day"))}
											disabledDate={disableDate}
											rules={FranchiseeInquiryValidation.birthdate}
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											label="Blood Group"
											name="blood_grp_id"
											placeholder="Select Blood Group"
											allowClear
											className="inputText"
											optionFilterProp="children"
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchBloodGrpList &&
												getBloodGrpList().then(() =>
													setFetchBloodGrpList(false)
												)
											}
											notFoundContent={
												fetchBloodGrpList ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: dropdown_blood_grp_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
								</Row>
								<Divider />
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Personal Mobile"
											placeholder="Personal Mobile"
											name="mobile"
											maxLength={10}
											onChange={() => {
												handleChange();
											}}
											disabled={isViewValues}
											rules={FranchiseeInquiryValidation.mobile}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Other Mobile"
											placeholder="Other Mobile"
											name="phone1"
											maxLength={10}
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.phone1}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Landline"
											maxLength={20}
											placeholder="Landline"
											name="phone2"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.phone2}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Whatsapp Number"
											placeholder="Whatsapp Number"
											name="whatsapp_no"
											maxLength={10}
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.wp_number}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Fax"
											placeholder="Fax"
											name="fax"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.fax}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Personal Email"
											placeholder="Personal Email"
											name="email"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.email}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Skype Id"
											placeholder="Skype Id"
											name="skype_id"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.skype_id}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Reference Given By"
											placeholder="Reference Given By"
											name="ref_by"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.ref_by}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Reference Mobile Number"
											placeholder="Reference Mobile Number"
											name="ref_mobile"
											maxLength={10}
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.ref_mobile}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
								</Row>
								<Divider />
								<Row>
									<Col span={24}>
										<h3>Resident Address</h3>
									</Col>
								</Row>
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="House/Apartment/Suit No."
											placeholder="House/Apartment/Suit No."
											name="address1"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address1}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Street Number or Name"
											placeholder="Street Number or Name"
											name="address2"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address2}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Landmark"
											placeholder="Landmark"
											name="address3"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address3}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											label="State"
											name="state_id"
											placeholder="Select State"
											required
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.state_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
												handleStateChange()
											}}
											onFocus={() =>
												fetchState &&
												getStateList().then((data: any) => {
													setStateList(data.states);
													setFetchState(false)
												}
												)
											}
											notFoundContent={
												fetchState ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: stateList,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="City"
											name="city_name"
											required
											placeholder="City"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.city}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											required
											label="Pin Code"
											placeholder="Pin Code"
											name="pincode_id"
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.pincode_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchPinCode && form?.getFieldValue("state_id") &&
												getPinCodeList({
													state_id: form.getFieldValue("state_id"),
												}).then((data: any) => {
													setPinCodeList(data.pincodes)
													setFetchPinCode(false)
												}
												)
											}
											notFoundContent={
												fetchPinCode ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: pinCodeList,
												valueKey: "id",
												textKey: "pincode",
											}}
											showSearch
										/>
									</Col>
								</Row>
								<Divider />
								<Row>
									<Col span={24}>
										<h3>Permanent/Native Address</h3>
									</Col>
								</Row>
								<Row gutter={10}>
									<Col xs={{ span: 8 }}>
										<Checkbox
											onChange={handleSameAsAboveChange}
											disabled={isViewValues}
										>
											Same as above
										</Checkbox>
									</Col>
								</Row>
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="House/Apartment/Suit No."
											placeholder="House/Apartment/Suit No."
											name="p_address1"
											onChange={() => {
												handleChange();
											}}
											disabled={isViewValues}
											rules={FranchiseeInquiryValidation.address1}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Street Number or Name"
											placeholder="Street Number or Name"
											name="p_address2"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address2}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Landmark"
											placeholder="Landmark"
											name="p_address3"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address3}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											label="State"
											name="p_state_id"
											placeholder="Select State"
											required
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.state_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
												handlePerStateChange()
											}}
											onFocus={() =>
												fetchPerState &&
												getStateList().then((data: any) => {
													setPermanentState(data.states)
													setFetchPerState(false)
												}
												)
											}
											notFoundContent={
												fetchPerState ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: permanentState ? permanentState : dropdown_state_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="City"
											name="p_city_name"
											placeholder="City"
											required
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.city}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											required
											label="Pin Code"
											placeholder="Pin Code"
											name="p_pincode_id"
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.pincode_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchPerPinCode && form?.getFieldValue("p_state_id") &&
												getPinCodeList({
													state_id: form.getFieldValue("p_state_id"),
												}).then((data: any) => {
													setPermanentPinCode(data.pincodes)
													setFetchPerPinCode(false)
												}
												)
											}
											notFoundContent={
												fetchPerPinCode ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: permanentPinCode ? permanentPinCode : dropdown_pin_code_list,
												valueKey: "id",
												textKey: "pincode",
											}}
											showSearch
										/>
									</Col>
								</Row>
								<Divider />
								<Row style={{ display: "block" }} gutter={10}>
									<FrKycFormComponent frKycFormDetails={frKycFormDetails} form={form} updateFrKycPayload={updateFrKycPayload} setFrDocIds={setFrDocIds} setDisabled={setDisabled} />
								</Row>

							</div>
							<div className="form">
								<div className="card_title">
									<h3>Location Details</h3>
								</div>
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											name="loc_name"
											required
											label="Location Name"
											placeholder="Location Name"
											className="inputText"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_name}
											disabled={isViewValues}
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<Form.Item name="loc_is_gst_registered" label="Is GST Registered?" valuePropName="checked"  >
											{<Switch
												checked={(form.getFieldValue('loc_is_gst_registered')) ? true : true}
												disabled={isViewValues}
												onChange={(e) => {
													setGstDisplay(e)
													handleChange()
													form.setFieldsValue({ loc_is_gst_registered: (e) ? 1 : 0 })
												}}
											/>
											}
										</Form.Item>
									</Col>
									{gstDisplay ?
										<Col xs={24} sm={12} md={12} lg={6}>

											<InputBox.Text
												type="text"
												label="GST No."
												placeholder="GST No."
												name="loc_gst_no"
												required
												maxLength={15}
												className="inputText"
												onChange={() => {
													handleChange();
												}}
												rules={FranchiseeInquiryValidation.gst_no}
												disabled={isViewValues}
											/>

										</Col>
										: null}
								</Row>
								<Divider />
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Location Contact Person"
											placeholder="Location Contact Person"
											name="loc_contact_person"
											required
											className="inputText"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_contact_person}
											disabled={isViewValues}
										/>
									</Col>
								</Row>
								<Divider />
								<Row>
									<Col span={24}>
										<h3>Location Address</h3>
									</Col>
								</Row>
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Shop/Apartment"
											placeholder="Shop/Apartment"
											name="loc_address1"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address1}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Area Name"
											placeholder="Area Name"
											name="loc_address2"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address2}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Landmark"
											placeholder="Landmark"
											name="loc_address3"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address3}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											label="State"
											name="loc_state_id"
											placeholder="Select State"
											required
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.state_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
												handleLocStateChange()
											}}
											onFocus={() =>
												fetchLocState &&
												getStateList().then((data: any) => {
													setLocStateList(data.states)
													setFetchLocState(false)
												}
												)
											}
											notFoundContent={
												fetchLocState ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: locStateList ? locStateList : dropdown_state_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											label="City"
											name="loc_city_id"
											placeholder="Select City"
											required
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.city_id}
											disabled={isViewValues}
											onChange={(e: any) => {
												const FindStdCode = dropdown_city_list.find((element: any) => element.id === e)
												setStdCode(FindStdCode?.std_code)
												form.setFieldsValue({
													phoneStdCode: FindStdCode?.std_code
												})
												handleChange();
												handleLocCityChange()
											}}
											onFocus={() =>
												fetchLocCity && form?.getFieldValue("loc_state_id") &&
												getCityList({
													state_id: form.getFieldValue("loc_state_id"),
												}).then(() =>
													setFetchLocCity(false)
												)
											}
											notFoundContent={
												fetchLocCity ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: dropdown_city_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											required
											label="Pin Code"
											placeholder="Pin Code"
											name="loc_pincode_id"
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.pincode_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchLocPinCode && form?.getFieldValue("loc_city_id") &&
												getPinCodeList({
													city_id: form.getFieldValue("loc_city_id"),
												}).then(() =>
													setFetchLocPinCode(false)
												)
											}
											notFoundContent={
												fetchLocPinCode ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: dropdown_pin_code_list,
												valueKey: "id",
												textKey: "pincode",
											}}
											showSearch
										/>
									</Col>
								</Row>
								<Divider />
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Office Mobile 1"
											placeholder="Office Mobile 1"
											maxLength={10}
											name="loc_mobile1"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.mobile}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Office Mobile 2"
											placeholder="Office Mobile 2"
											name="loc_mobile2"
											maxLength={10}
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.mobile2}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Landline 1"
											placeholder="Landline 1"
											addonBefore={stdCode}
											maxLength={20}
											name="loc_phone1"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_phone1}
											disabled={isViewValues}
											className="inputText"
										/>
										<input type="text" hidden={true} name="phoneStdCode" />
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Landline 2"
											placeholder="Landline 2"
											addonBefore={stdCode}
											maxLength={20}
											name="loc_phone2"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_phone2}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Whatsapp Number"
											placeholder="Whatsapp Number"
											name="loc_whatsapp_no"
											maxLength={10}
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.wp_number}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
								</Row>
								<Divider />
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Fax"
											placeholder="Fax"
											name="loc_fax"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.fax}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Email"
											placeholder="Email"
											name="loc_email"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.email}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Skype Id"
											placeholder="Skype Id"
											name="loc_skype_id"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.skype_id}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
								</Row>
								<Divider />

								<Row gutter={10}>
									<Col span={24}>
										<h3>Location Incharge</h3>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Incharge Name"
											placeholder="Incharge Name"
											name="loc_incharge_name"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_incharge_name}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Incharge Mobile"
											placeholder="Incharge Mobile"
											maxLength={10}
											name="loc_incharge_mobile"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.mobile}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Incharge Email"
											placeholder="Incharge Email"
											name="loc_incharge_email"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_incharge_email}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
								</Row>
								<Divider />
								<Row>
									<Col span={24}>
										<h3>Legal Information</h3>
									</Col>
								</Row>
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											required
											label="Location Type"
											name="ct_id"
											placeholder="Select Location Type"
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.ct_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchLocationTypes &&
												getCenterTypesList().then(() =>
													setFetchLocationTypes(false)
												)
											}
											notFoundContent={
												fetchLocationTypes ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: dropdown_center_types_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											required
											label="Partnership Type"
											name="pt_id"
											placeholder="Select Partnership Type"
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.pt_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchPartnerShipType &&
												getPartnerShipTypesList().then(() =>
													setFetchPartnerShipType(false)
												)
											}
											notFoundContent={
												fetchPartnerShipType ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: dropdown_partnership_types_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
								</Row>
								<Divider />
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											required
											label="SEZ"
											name="sez_id"
											placeholder="Select SEZ"
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.sez_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchSez &&
												getSezList().then(() =>
													setFetchSez(false)
												)
											}
											notFoundContent={
												fetchSez ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: dropdown_sez_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
								</Row>
								<Divider />
								<Row>
									<Col span={24}>
										<h3>Location Bank Info</h3>
									</Col>
								</Row>
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											required
											label="Account No."
											placeholder="Account No."
											name="loc_bank_accno"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_bank_account}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="IFSC"
											placeholder="IFSC"
											name="loc_bank_ifsc"
											required
											onChange={(e: any) => { handleChange(); setButtonEvent(e.target.value) }}
											rules={FranchiseeInquiryValidation.loc_bank_ifsc}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<Form.Item className="searchBtn">
											<Button type="primary"
												disabled={((buttonEvent && (buttonEvent.length >= 8 && buttonEvent.length <= 20)) ? false : true) || isViewValues}
												onClick={() => {
													const ifsc_data = form.getFieldValue('loc_bank_ifsc')
													getBankInfo({ ifsc: ifsc_data }).then((data: any) => {
														FRANCHISEE_INQUIRY.dropdown_state_list = [{ id: data?.state_id, name: data?.state }]
														FRANCHISEE_INQUIRY.dropdown_city_list = [{ id: data?.city_id, name: data?.city }]
														if (data.bank) {
															FRANCHISEE_INQUIRY.dropdown_bank_list = [{ id: data?.bank_id, name: data?.bank }]
															setFetchBank(true);
														}
														form.setFieldsValue({
															loc_bank_branch: data.branch,
															loc_bank_id: data.bank_id,
															loc_bank_micr: data.micr,
															loc_bank_swift: data.swift,
															loc_bank_address1: data.address1,
															loc_bank_address2: data.address2,
															loc_bank_address3: data.address3,
															loc_bank_state_id: data.state_id,
															loc_bank_city_id: data.city_id,
															loc_bank_pincode: data.pincode
														})
														handleChange()
													})
												}}><SearchOutlined className="inputText" /></Button>
										</Form.Item>
									</Col>
								</Row>
								<Divider />
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Branch"
											placeholder="Branch"
											name="loc_bank_branch"
											required
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_bank_branch}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											required
											label="Bank"
											name="loc_bank_id"
											placeholder="Select Bank"
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.loc_bank_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchBank &&
												getBanksList({ is_active: 1 }).then(() =>
													setFetchBank(false)
												)
											}
											notFoundContent={
												fetchBank ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: dropdown_bank_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="MICR"
											placeholder="MICR"
											name="loc_bank_micr"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_bank_micr}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Swift"
											placeholder="Swift"
											name="loc_bank_swift"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.loc_bank_swift}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
								</Row>
								<Divider />
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Address Line 1"
											placeholder="Address Line 1"
											name="loc_bank_address1"
											required
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address1}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Address Line 2"
											placeholder="Address Line 2"
											name="loc_bank_address2"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address2}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Address Line 3"
											placeholder="Address Line 3"
											name="loc_bank_address3"
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.address3}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
								</Row>
								<Row gutter={10}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											label="State"
											name="loc_bank_state_id"
											placeholder="Select State"
											required
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.state_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
												handleLocBankStateChange()
											}}
											onFocus={() =>
												fetchLocBankState &&
												getStateList().then((data: any) => {
													setLocBankStateList(data.states)
													setFetchBankLocState(false)
												}
												)
											}
											notFoundContent={
												fetchLocBankState ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: locBankStateList ? locBankStateList : dropdown_state_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Select
											label="City"
											name="loc_bank_city_id"
											placeholder="Select City"
											required
											allowClear
											className="inputText"
											optionFilterProp="children"
											rules={FranchiseeInquiryValidation.city_id}
											disabled={isViewValues}
											onChange={() => {
												handleChange();
											}}
											onFocus={() =>
												fetchLocBankCity && form?.getFieldValue("loc_bank_state_id") &&
												getCityList({
													state_id: form.getFieldValue("loc_bank_state_id"),
												}).then((data: any) => {
													setLocBankCityList(data.cities)
													setFetchLocBankCity(false)
												}
												)
											}
											notFoundContent={
												fetchLocBankCity ? <Spin size="small" /> : "No Record Found."
											}
											options={{
												list: locBankCityList ? locBankCityList : dropdown_city_list,
												valueKey: "id",
												textKey: "name",
											}}
											showSearch
										/>
									</Col>
									<Col xs={24} sm={12} md={12} lg={6}>
										<InputBox.Text
											type="text"
											label="Pin Code"
											placeholder="Pin Code"
											name="loc_bank_pincode"
											required
											onChange={() => {
												handleChange();
											}}
											rules={FranchiseeInquiryValidation.pincode}
											disabled={isViewValues}
											className="inputText"
										/>
									</Col>
								</Row>
								<Divider />
								<Row style={{ display: "block" }} gutter={10}>
									<LocKycFormComponent locKycFormDetails={locKycFormDetails} form={form} updateLocKycPayload={updateLocKycPayload} setLocDocIds={setLocDocIds} setDisabled={setDisabled} />
								</Row>

							</div>
							{
								!isViewValues &&
								<div className="button">
									<Button style={{ margin: "0 15px 30px 0" }} type="primary" htmlType="button" key="1" onClick={() => {
										onSubmit(form.getFieldsValue(), { is_draft: 0 })
									}}>
										Save As A Draft
									</Button>
									<Button type="primary" disabled={disabled} loading={loading} htmlType="button" key="2" onClick={() => {
										onSubmit(form.getFieldsValue(), { is_draft: 1 })
									}}>
										Finalize
									</Button>
								</div>
							}
						</FormBox>
					</div>
				</div>
			</div>
		</>
	);
});
export default FormComponent;
