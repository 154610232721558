const API_URL = {
	HEADER: "init/header",
	FOOTER: "init/footer",
	LOGIN: "auth/login",
	LOGOUT:"auth/logout",
	CHANGE_PASSWORD :"auth/change-password",
	POPUP: "general/communication",
	//EMAIL_VERIFY:(token:string) => `account-parties/verify-email/${token}`,
	EMAIL_VERIFY: "account-parties/verify-email",
	USER_ACCOUNT_VERIFY: (usertoken:string) => `auth/email-verification/${usertoken}`,
	BOOKING_LIST :"bookings/list",
	DOWNLOAD_POD:"bookings/download-pod",
	PAGES: {
		HOME: "pages/home",
		TRACKDATA: "tracking/web-view/consignment",
		TRACKDATABANNER: "pages/tracking",
		ABOUT: "pages/about-us",
		CONTACT: "pages/contact-us",
		NETWORK: "pages/network",
		SERVICE: "pages/service",
		PRIVACY: "pages/privacy-policy",
		LOCATION: "search/location-list",
		CENTERDETAIL: "search/location-detail",
		CONTACTFORM: "pages/contact-form",
		FRANCHISEE: "pages/franchisee-enquiry",
		STATELIST:"pages/states",
		SENDTRACKEMAIL:"tracking/send-pod-email",
		LOCATIONLIST:"account-parties/corporate/lov",
		PIN_CODE_LIST: "pages/pincodes",
		CITY_LIST: "pages/cities",
		BLOOD_GRP_LIST: "pages/blood-groups",
		FRANCHISEE_INQUIRY_CREATE : "franchisee-inquiry/new",
		FRANCHISEE_INQUIRY_DETAIL : "franchisee-inquiry/detail",
		CENTER_TYPE: "pages/center-types",
		PARTNERSHIP_TYPE: "pages/partnership-types",
		SEZ:"pages/sez",
		BANK_INFO: "pages/get-bank-info",
		BANK_LIST: "pages/banks",
		KYC_DETAILS: "franchisee-inquiry/kyc-details"
	}
};
export default API_URL;