import React from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { Messages } from "../../../../config/Message/Common";
import { useParams } from "react-router-dom";

interface DataErrorPageProps {
	notMsg: string;
}
const DataErrorPage: React.FC<DataErrorPageProps> = observer(({ notMsg }) => {
	const { id } = useParams<{ id: string }>();

	return (
		<div className="tableDetails">
			<div className="titleColor bgColorBlack">
				Shipment Information: <span>{id ?? Messages.NOT_DATA}</span>
			</div>
			<div className="tableDetails">
				<Row gutter={16}>
					<Col xs={24} sm={24} className="nodata">
						{notMsg ?? Messages.NO_RECORD}
					</Col>
				</Row>
			</div>
		</div>
	);
});
export default DataErrorPage;
