import React, { useEffect } from "react";
import ListComponent from "./Listing/ListComponent";
import useStore from "../../store";
import { Constant } from "../../config/Constant";
import FranchiseeInquiry from "../../views/app_pages/FranchiseeInquiry";


const CLientLogin: React.FC = () => {
    const {AUTH: {userDetails}} = useStore()

    const scrollToTop = () => {
		window.scrollTo({
			top:0,
			behavior: "smooth",
		});
	};
	useEffect(()=>{
        scrollToTop();
    },[])
    return (
        <>
            {
                userDetails?.role.id === Constant.ROLES.FRANCHISEE_INQUIRY ?
                    <FranchiseeInquiry /> :
                    <section >
                        <div className="container listComponent" >
                            <ListComponent />
                        </div>
                    </section>
            }
    </>
    );
}

export default CLientLogin;

