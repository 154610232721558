export const Regex = {
	password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*[0-9]).*$/,
	mobile: /^[0-9]+$/,
	twoDecimal: /^\d*(\.\d{0,2})?$/,
	digitCommaSpace: /^[0-9 ,]*$/,
	charactersSpace: /^[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/,
	number: /^[0-9]*$/,
	secreteCode:/^[a-zA-Z0-9!@#%^&*()-.+,_'""= ;\n]*$/,
//secreteCode:/("[\w-\s]+"|^[a-zA-Z0-9!?@#%^&*()-.+,_'"= ]*$)/
	PHONE_NUMBER : /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/,
	EMAIL_ID :/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
	number_withdash:/^[0-9-]*$/,
	PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
	AADHAR: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
	PHONE: /^[0-9 /()-]*$/,
	GST_IN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z1-9]{1}[Z]{1}[A-Z0-9]{1}$/,
	ACCOUNT: /^([0-9]+)$/,
	ALPHANUMERIC: /^[A-Za-z0-9]*$/,
	PINCODE: /^[1-9]{1}[0-9]{5}$/,
};

// /^(?=.*[0-9])/

export const minFirstName = 3;
export const maxFirstName = 50;
export const minLastName = 3;
export const maxLastName = 50;
export const minMobile = 8;
export const maxMobile = 20;
export const minEmail = 3;
export const maxEmail = 70;
export const minPassword = 8;
export const maxPassword = 20;
export const minName = 3;
export const maxName = 50;
// new for tracking number
export const minNumber = 11;
export const maxNumber = 13;