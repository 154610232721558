import { createContext, useContext } from "react";
import { Context } from "vm";
import AuthStore from "./AuthStore/AuthStore";
import RootStore from "./RootStore/RootStore";
import HomeStore from "../views/app_pages/Home/HomeStore";
import TrackingStore from "../views/app_pages/TrackShipment/TrackingStore";
import AboutStore from "../views/app_pages/AboutUs/AboutStore";
import ContactStore from "../views/app_pages/OurContact/ContactStore";
import NetworkStore from "../views/app_pages/Network/NetworkStore";
import PrivacyStore from "../views/app_pages/PrivacyPolicy/PrivacyStore";
import ServiceStore from "../views/app_pages/ServiceGuide/ServiceStore";
import SearchLocationStore from "../views/app_pages/Network/SearchLocationStore";
import {ClientStore,ChangePasswordStore} from "../components/ClientLogin/Component/ClientStore";
import ChannelPartnerStore from "../views/app_pages/ChannelPartner/ChannelPartnerStore";
import FranchiseeInquiryStore from "../views/app_pages/FranchiseeInquiry/FranchiseeInquiryStore";

const AppContext = createContext({
	ROOT: new RootStore(),
	AUTH: new AuthStore(),
	TRACKDATA: new TrackingStore(),
	HOME: new HomeStore(),
	ABOUT: new AboutStore(),
	CONTACT: new ContactStore(),
	NETWORK:new NetworkStore(),
	PRIVACY: new PrivacyStore(),
	SERVICE: new ServiceStore(),
	LOCATION: new SearchLocationStore(),
	CLIENTLIST: new ClientStore(),
	CHANGEPASSWORD: new ChangePasswordStore(),
	FRANCHISEE: new ChannelPartnerStore(),
	FRANCHISEE_INQUIRY : new FranchiseeInquiryStore()
});

const useStore = (): Context => useContext(AppContext);

export default useStore;
