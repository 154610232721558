import { observer } from "mobx-react";
import React, { useState } from "react";
import FormComponent from "../Component/FormComponent"
import { Form } from "antd";

const AddComponent: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [fetchState, setFetchState] = useState(true);
	const [fetchPinCode, setFetchPinCode] = useState(true);
	const [fetchPerState, setFetchPerState] = useState(true);
	const [fetchPerPinCode, setFetchPerPinCode] = useState(true);
	const [fetchLocCity, setFetchLocCity] = useState(true);
	const [pinCodeList, setPinCodeList] = useState();
	const [permanentPinCode, setPermanentPinCode] = useState();
	const [fetchLocState, setFetchLocState] = useState(true);
	const [fetchLocPinCode, setFetchLocPinCode] = useState(true);
	const [stdCode, setStdCode] = useState()
	const [stateList, setStateList] = useState();
	const [permanentState, setPermanentState] = useState();
	const [fetchBloodGrpList, setFetchBloodGrpList] = useState(true);
	const [gstDisplay, setGstDisplay] = useState(true);
	const [fetchLocationTypes, setFetchLocationTypes] = useState(true);
	const [fetchPartnerShipType, setFetchPartnerShipType] = useState(true);
	const [fetchSez, setFetchSez] = useState(true);
	const [buttonEvent, setButtonEvent] = useState()
	const [fetchBank, setFetchBank] = useState(true);
	const [fetchLocBankState, setFetchBankLocState] = useState(true);
	const [fetchLocBankCity, setFetchLocBankCity] = useState(true);
	const [locStateList, setLocStateList] = useState();
	const [locBankStateList, setLocBankStateList] = useState();
	const [locBankCityList, setLocBankCityList] = useState();

	return (
		<>
			<div className="container">
				<div className="clientListing">
					<h1>Franchisee Inquiry Details</h1>
				</div>
			</div>
			<FormComponent
				form={form}
				loading={loading}
				setLoading={setLoading}
				fetchState={fetchState}
				setFetchState={setFetchState}
				fetchPinCode={fetchPinCode}
				setFetchPinCode={setFetchPinCode}
				fetchPerState={fetchPerState}
				setFetchPerState={setFetchPerState}
				fetchPerPinCode={fetchPerPinCode}
				setFetchPerPinCode={setFetchPerPinCode}
				fetchLocCity={fetchLocCity}
				setFetchLocCity={setFetchLocCity}
				pinCodeList={pinCodeList}
				setPinCodeList={setPinCodeList}
				permanentPinCode={permanentPinCode}
				setPermanentPinCode={setPermanentPinCode}
				fetchLocState={fetchLocState}
				setFetchLocState={setFetchLocState}
				fetchLocPinCode={fetchLocPinCode}
				setFetchLocPinCode={setFetchLocPinCode}
				stdCode={stdCode}
				setStdCode={setStdCode}
				stateList={stateList}
				setStateList={setStateList}
				permanentState={permanentState}
				setPermanentState={setPermanentState}
				fetchBloodGrpList={fetchBloodGrpList}
				setFetchBloodGrpList={setFetchBloodGrpList}
				gstDisplay={gstDisplay}
				setGstDisplay={setGstDisplay}
				fetchLocationTypes={fetchLocationTypes}
				setFetchLocationTypes={setFetchLocationTypes}
				fetchPartnerShipType={fetchPartnerShipType}
				setFetchPartnerShipType={setFetchPartnerShipType}
				fetchSez={fetchSez}
				setFetchSez={setFetchSez}
				buttonEvent={buttonEvent}
				setButtonEvent={setButtonEvent}
				fetchBank={fetchBank}
				setFetchBank={setFetchBank}
				fetchLocBankState={fetchLocBankState}
				setFetchBankLocState={setFetchBankLocState}
				fetchLocBankCity={fetchLocBankCity}
				setFetchLocBankCity={setFetchLocBankCity}
				locStateList={locStateList}
				setLocStateList={setLocStateList}
				locBankStateList={locBankStateList}
				setLocBankStateList={setLocBankStateList}
				locBankCityList={locBankCityList}
				setLocBankCityList={setLocBankCityList}
			/>
		</>
	);
});
export default AddComponent;
