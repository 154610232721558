import { observer } from "mobx-react";
import React from "react";
import AddComponent from "./Component/AddComponent";

const FranchiseeInquiry: React.FC = observer(() => {

	return (
		<>
			<AddComponent />
		</>
	);
});
export default FranchiseeInquiry;
