import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";

export interface DropdownStateListRes {
	id: number;
	name: string;
}

export interface DropdownPinCodeListRes {
	id: number;
	pincode: string;
}

export interface DropdownCityListRes {
	id: number;
	name: string;
	std_code: number
}

export interface DropdownBloodGrpListRes {
	id: number;
	name: string;
}

export interface DropdownCenterTypeListRes {
	id: number;
	name: string;
}

export interface DropdownPartnerShipTypeListRes {
	id: number;
	name: string;
}

export interface DropdownSezTypeListRes {
	id: number;
	name: string;
}

export interface DropdownBankListRes {
	id: number;
	name: string;
}

export default class FranchiseeInquiryStore {
	public dropdown_state_list: DropdownStateListRes[] = []
	public dropdown_pin_code_list: DropdownPinCodeListRes[] = []
	public dropdown_city_list: DropdownCityListRes[] = []
	public dropdown_blood_grp_list: DropdownCityListRes[] = []
	public dropdown_center_types_list: DropdownCenterTypeListRes[] = []
	public dropdown_partnership_types_list: DropdownPartnerShipTypeListRes[] = []
	public dropdown_sez_list: DropdownSezTypeListRes[] = []
	public dropdown_bank_list: DropdownBankListRes[] = []
	public franchiseeDetailsData = null

	constructor() {
		makeAutoObservable(this);

	}

	public setFranchiseeDetailsData = (data: any) => {
		this.franchiseeDetailsData = data
	}

	public getStateList = async (): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.STATELIST)
			.then(({ data }) => {
				this.dropdown_state_list = data.states;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getPinCodeList = async (data: number): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.PIN_CODE_LIST, data)
			.then(({ data }) => {
				this.dropdown_pin_code_list = data.pincodes;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getCityList = async (data: number): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.CITY_LIST, data)
			.then(({ data }) => {
				this.dropdown_city_list = data.cities;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getBloodGrpList = async (): Promise<any> => {
		return await axios
			.get(API_URL.PAGES.BLOOD_GRP_LIST)
			.then(({ data }) => {
				this.dropdown_blood_grp_list = data.bloodGroups;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getCenterTypesList = async (): Promise<any> => {
		return await axios
			.get(API_URL.PAGES.CENTER_TYPE)
			.then(({ data }) => {
				this.dropdown_center_types_list = data.centerTypes;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getPartnerShipTypesList = async (): Promise<any> => {
		return await axios
			.get(API_URL.PAGES.PARTNERSHIP_TYPE)
			.then(({ data }) => {
				this.dropdown_partnership_types_list = data.partnershipTypes;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getSezList = async (): Promise<any> => {
		return await axios
			.get(API_URL.PAGES.SEZ)
			.then(({ data }) => {
				this.dropdown_sez_list = data.sez;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	//Get Bank info 
	public getBankInfo = async (data: string): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.BANK_INFO, data)
			.then(({ data }) => {
				return data.bank_info;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getBanksList = async (data: number): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.BANK_LIST, data)
			.then(({ data }) => {
				this.dropdown_bank_list = data.banks;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public createFranchiseeInquiryData = async (payload: any): Promise<any> => {
		return await axios
			.post(`${API_URL.PAGES.FRANCHISEE_INQUIRY_CREATE}`, payload)
			.then(({ data }) => {
				return data;
			})
			.catch((response: any) => {
				const errors: any = [];
				let notify = null;
				if (response.data && response?.data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = response?.data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: response?.data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	public DetailsData = async (): Promise<any> => {
		return await axios
			.get(API_URL.PAGES.FRANCHISEE_INQUIRY_DETAIL)
			.then(({ data }) => {
				this.setFranchiseeDetailsData(data.details)
				return data.details;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
	public getKycForm = async (): Promise<any> => {
		return await axios
			.get(API_URL.PAGES.KYC_DETAILS)
			.then(({ data }) => {
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
